import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textarea", "counter"];

  connect() {
    this.updateCounter();
  }

  updateCounter() {
    const maxChars = parseInt(this.textareaTarget.dataset.maxchars) || 280;
    const currentLength = this.calculateAdjustedLength(this.textareaTarget.value);
    
    if (currentLength > maxChars) {
      this.textareaTarget.value = this.trimToMaxChars(this.textareaTarget.value, maxChars);
    }

    this.updateCounterDisplay();
  }

  handlePaste(event) {
    const maxChars = parseInt(this.textareaTarget.dataset.maxchars) || 280;
    const pastedText = (event.clipboardData || window.clipboardData).getData("text");

    const adjustedLength = this.calculateAdjustedLength(this.textareaTarget.value + pastedText);

    if (adjustedLength > maxChars) {
      event.preventDefault();
      const allowedText = this.trimToMaxChars(this.textareaTarget.value + pastedText, maxChars);
      this.textareaTarget.value = allowedText;
    }

    this.updateCounterDisplay();
  }

  calculateAdjustedLength(text) {
    const newlineCount = (text.match(/\n/g) || []).length;
    return text.length + newlineCount;
  }

  trimToMaxChars(text, maxChars) {
    let length = 0;
    let endIndex = 0;

    while (endIndex < text.length && length < maxChars) {
      length += text[endIndex] === '\n' ? 2 : 1;
      if (length <= maxChars) endIndex++;
    }

    return text.substring(0, endIndex);
  }

  updateCounterDisplay() {
    const maxChars = parseInt(this.textareaTarget.dataset.maxchars) || 280;
    const currentLength = this.calculateAdjustedLength(this.textareaTarget.value);
    this.counterTarget.textContent = `${currentLength}/${maxChars}`;
  }
}
