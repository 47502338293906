// app/javascript/controllers/character_limit_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "toggle"]

  connect() {
    this.maxChars = 100 // Define your character limit here
    const fullText = this.contentTarget.dataset.fullText

    // Only show toggle if content exceeds maxChars
    if (fullText.length > this.maxChars) {
      this.expanded = false // Track whether content is expanded
      this.updateContent()
    } else {
      this.toggleTarget.classList.add("hidden") // Hide the button if content is within the limit
      this.contentTarget.innerText = fullText // Show full text without truncation
    }
  }

  toggle() {
    this.expanded = !this.expanded
    this.updateContent()
  }

  updateContent() {
    const fullText = this.contentTarget.dataset.fullText

    if (this.expanded) {
      this.contentTarget.innerText = `${fullText} `
      this.toggleTarget.innerText = "See less"
    } else {
      const truncatedText = fullText.substring(0, this.maxChars)
      this.contentTarget.innerText = `${truncatedText}... `
      this.toggleTarget.innerText = "See more"
    }
  }
}
